import React from 'react';
import ReactDOM from 'react-dom';
import App from './scenes';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { config } from './services/firebase';
// import { createFirestoreInstance } from 'redux-firestore';
// import firebase from 'firebase/app';
// import 'firebase/functions';
// import 'firebase/analytics';
// import 'firebase/storage';
import createReduxStore from './store';
import localForage from 'localforage';

import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import 'firebase/compat/analytics';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import { createFirestoreInstance } from 'redux-firestore';

localForage.config({
  driver: [localForage.INDEXEDDB, localForage.LOCALSTORAGE, localForage.WEBSQL],
  name: 'Reach Portal',
  version: 1.0
});

export const rrfConfig = {
  userProfile: 'accounts',
  //attachAuthIsReady: true,
  useFirestoreForProfile: true
};

// Initialize firebase instance
firebase.initializeApp(config);

// Initialize other services on firebase instance
firebase.auth();
firebase.analytics();

if (process.env.REACT_APP_ENV === 'local') {
  // firebase.firestore().settings({ host: 'localhost:8080', ssl: false });
  firebase.functions().useFunctionsEmulator('http://localhost:5001');
} else {
  firebase.firestore().settings({});
  firebase.functions();
}

// Export authRef and provider
export const authRef = firebase.auth();
export const provider = new firebase.auth.GoogleAuthProvider();
export const storage = firebase.storage();
export const voicemailStorage = firebase
  .app()
  .storage(process.env.REACT_APP_FIREBASE_STORAGE_VOICEMAIL_BUCKET);
export const db = firebase.firestore();

const store = createReduxStore();

export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
};

const Root = () => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <BrowserRouter>
            <Route path='/' component={App} />
          </BrowserRouter>
        </ReactReduxFirebaseProvider>
      </Provider>
    </React.StrictMode>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));
